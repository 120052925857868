<template>
	<div>
		<div class="page-header hidden-sm-and-down">
			<el-page-header @back="$router.go(-1)" content="专家统计" />
		</div>
		<searchForm style="margin: 15px 0" />
		<el-row :gutter="10" style="margin:20px 0">
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>专业分布</span>
						<el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
					</div>
					<div v-for="o in 4" :key="o" class="text item">
						{{ '列表内容 ' + o }}
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>职称分布</span>
						<el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
					</div>
					<div v-for="o in 4" :key="o" class="text item">
						{{ '列表内容 ' + o }}
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>学历分布</span>
						<el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
					</div>
					<div v-for="o in 4" :key="o" class="text item">
						{{ '列表内容 ' + o }}
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover">
					<div slot="header" class="clearfix">
						<span>年龄分布</span>
						<el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
					</div>
					<div v-for="o in 4" :key="o" class="text item">
						{{ '列表内容 ' + o }}
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import searchForm from './widgets/searchForm.vue';
export default {
	components: {
		searchForm,
	}
}
</script>